.kn-item__item___kQ2Ll {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  cursor: pointer; }
  .kn-item__item___kQ2Ll .kn-item__selected___3tRL6 {
    background-color: #f7f7f7; }
  .kn-item__item___kQ2Ll:hover {
    background-color: #f3f3f3; }

.kn-item__disabled___3xX7z {
  color: #b4b4b4;
  cursor: default; }

.kn-item__selected___3tRL6 {
  background-color: #f7f7f7; }

.kn-item__with_border___3n9sw {
  border-bottom: 1px solid #dddddd; }

.kn-item__with_grouping___3IJz8 {
  color: #000000;
  padding-left: 10px; }
