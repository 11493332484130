.kn-selected_item__selected_item___1uiiv, .kn-selected_item__with_grouping___1Ibnd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 12px;
  margin-right: -4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer; }
  .kn-selected_item__selected_item___1uiiv:hover, .kn-selected_item__with_grouping___1Ibnd:hover {
    background-color: #f3f3f3; }

.kn-selected_item__with_grouping___1Ibnd {
  pointer-events: none;
  color: #000000; }

.kn-selected_item__disabled___1430r {
  color: #b4b4b4;
  background-color: #f7f7f7;
  cursor: default; }
