.kn-list__list___22Wuc {
  outline: none;
  user-select: none; }

.kn-list__list_item___1mDV9 {
  display: flex;
  flex-direction: column; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar, .kn-list__list___22Wuc::-webkit-scrollbar {
  width: 15px;
  height: 17px; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb, .kn-list__list___22Wuc::-webkit-scrollbar-thumb {
  background: #CDCDCD;
  border-color: transparent;
  border-style: solid;
  background-clip: content-box; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb:vertical, .kn-list__list___22Wuc::-webkit-scrollbar-thumb:vertical {
  min-height: 45px;
  border-width: 4px 4px 4px 5px; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-thumb:horizontal, .kn-list__list___22Wuc::-webkit-scrollbar-thumb:horizontal {
  min-width: 45px;
  border-width: 5px 4px 6px; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-track, .kn-list__list___22Wuc::-webkit-scrollbar-track {
  background: #ffffff; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-track:vertical, .kn-list__list___22Wuc::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #EDEDED;
  border-right: none; }

.kn-list__scrollbar___1a9W-::-webkit-scrollbar-track:horizontal, .kn-list__list___22Wuc::-webkit-scrollbar-track:horizontal, .kn-list__scrollbar___1a9W-::-webkit-scrollbar-corner, .kn-list__list___22Wuc::-webkit-scrollbar-corner {
  border-top: 1px solid #EDEDED;
  border-bottom: none; }
