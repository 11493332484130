.kn-search__search___3Zpb2 {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 0;
  border-bottom: 1px solid #dddddd; }

.kn-search__input___2tyxf {
  height: 45px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  flex: 1;
  outline: none;
  border: 0;
  font-size: 14px;
  background: transparent; }

.kn-search__icon___1frx4 {
  font-size: 24px; }

.kn-search__list_filter_container___2FaR9 {
  margin-bottom: 10px;
  position: relative; }
