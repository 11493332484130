.kn-selection_status__selection_status___1FzyX {
  height: 45px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  justify-content: space-between; }

.kn-selection_status__status___1qxE9 {
  color: #000000; }

.kn-selection_status__clear_all___3gppf {
  cursor: pointer;
  color: #008BBF; }
